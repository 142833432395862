import React,{useEffect, useState} from 'react';
import { Card, Grid, Typography,Stack, TextField, Autocomplete} from '@mui/material';
import moment from 'moment';
import { MapInteractionCSS } from 'react-map-interaction';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import MUIDataTable from "mui-datatables";
import colorConfigs from '../../configs/colorConfigs';

import AppText from '../../Utils/AppText';
import DataServices from '../../Services/DataServices';
import BackDropLoading from '../../Components/Loading';
import { showToast } from '../../Components/ToastBox';




const UwbTrackingPage = () => {
 
      const [data, setData] = useState();
      console.log('data;;;;;;;;;;;;',data)
      const [unsortedData, setUnsortedData] = useState([]);
        const [orgDropDownOptions, setOrgDropDownOptions] = useState([]);
          const [selectedOrganization, setSelectedOrganization] =
            useState([]);
            console.log(
              "selectedOrganization;;;;;;;;;;;;",
              selectedOrganization,
              orgDropDownOptions
            );


      const [timeLineData ,setTimeLineData]=useState([]);
      const [isLoading, setIsLoading] = useState(false);
      
      useEffect(() => {
        const uwRTrackingCall = async () => {
          setIsLoading(true);

          const response = await DataServices.uwbTracking(selectedOrganization.value);
          if (response && response?.statusCode === "200") {
            setIsLoading(false);
            setUnsortedData(response.response.body);
            const processedData = filterLatestEntries(response.response.body);
            setData(processedData);
          } else {
            setIsLoading(false);
            console.log(response);
          }
        };

        uwRTrackingCall();
      }, [selectedOrganization]);
    

       useEffect(() => {
         const fetchOrgDropdownData = async () => {
           try {
             setIsLoading(true);
             const response = await DataServices.getOrgDropDownDetailsApi();
             if (response.statusCode == 200) {
               try {
                 var dummy = [...response.response.body];
                 if (orgDropDownOptions < 1) {
                   setOrgDropDownOptions(dummy);
                   setSelectedOrganization(dummy[0]);
                 }
               } catch (error) {
                 showToast("Something went wrong ", "error");
               }
             } else {
               showToast(response.statusDescription, "error");
             }
           } finally {
             setIsLoading(false);
           }
         };
         fetchOrgDropdownData();
       }, []);

        
    const columns = [
        
        {
          name: "email",
          label: "Name",
          options: {
            sort: true,
        
            
          },
        },
        {
          name: "lastSeenTime",
          label: "Last Seen",
          options: {
            sort: true,
            customBodyRender: (value) => (
              moment.utc(value).local().format('YYYY-MM-DD HH:mm:ss')
            ),
          },
        },
        {
          name: "location",
          label: "Location",
          options: {
            sort: true,   
          },
  
        },
    ]

    


    const options = {
      onRowClick: (rowData) => handleRowClick(rowData[0]),
      
        filter: true,
        search: true,
        pagination: true,
        selectableRows: "none", 
        serverSide: false,
        download: false,
        print: false,
        viewColumns: false,
        responsive: "standard"
        
      };

  

      const filterLatestEntries = (entries) => {
        // Create a map to store the latest entry for each email based on exit time
        const latestEntriesMap = new Map();
        entries.forEach((entry) => {
          const currentExitTime = moment(entry.exitTime);
          const existingEntry = latestEntriesMap.get(entry.email);
    
          if (!existingEntry || currentExitTime.isAfter(moment(existingEntry.exitTime))) {
            latestEntriesMap.set(entry.email, entry);
          }
        });
    
        // Convert the map values back to an array
        const latestEntries = Array.from(latestEntriesMap.values());
        return latestEntries;
      };

      const handleRowClick = (email) => {
        // Filter the timeline data for the selected email
        const filteredData = unsortedData.filter((uwbData) => uwbData.email === email);
        setTimeLineData(filteredData);
      };

    return (
      <Grid container columnSpacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5" style={{ marginBottom: "20px" }}>
            {AppText.heading.uwbtracking}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="OrganizationName"
            options={orgDropDownOptions}
            sx={{ width: 250, float: "left", marginBottom: "20px" }}
            value={selectedOrganization}
            onChange={(event, newValue) =>
           
              setSelectedOrganization(newValue)
            }
           
            renderInput={(params) => (
              <TextField {...params} label="Organization Name" />
            )}
          />
          
                  </Grid>
        <Grid item xs={12} md={6} sm={12}>
          {/* MUIDataTable */}
          <MUIDataTable data={data} columns={columns} options={options} />
        </Grid>
        {/* <Grid item xs={12} sm={12} md={4} style={{ height: '100vh',display:"flex", justifyContent:"center", alignItems:"center"}} >
      <MapInteractionCSS showControls defaultValue={{
        scale: 1,
        translation: { x: 0, y: 0 }
      }}
      minScale={0.5}
      maxScale={3}
      translationBounds={{
        xMax: 400,
        yMax: 200
      }}>
        <img src={layout} style={{ width: '100%', height: '100%'}} alt="layout" />
      </MapInteractionCSS>
      </Grid> */}
        <Grid item xs={12} sm={12} md={6}>
          <Card
            variant="outlined"
            sx={{
              p: 2,
              width: { xs: "100%", md: "100%", sm: "auto" },
              display: "flex",
              flexDirection: { xs: "column", md: "row", sm: "row" },
              alignItems: "center",
              marginTop: { xs: 2, md: 0, sm: 2 },
            }}
          >
            <Typography variant="h6">{AppText.timeLine}</Typography>
          </Card>

          <Timeline
            sx={{
              "& .MuiTimelineItem-root:before": {
                flex: 0,
                padding: 0,
              },
              "& .MuiTimeline-positionRight:before": {
                padding: 0,
              },
            }}
          >
            {timeLineData.length > 0 ? (
              timeLineData.map((uwbData, index) => (
                <TimelineItem key={index} style={{ margin: 0, padding: 0 }}>
                  <TimelineSeparator color="primary">
                    <TimelineDot color="primary" />
                    {index < timeLineData.length - 1 && (
                      <TimelineConnector color="primary" />
                    )}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Card
                      variant="outlined"
                      sx={{
                        p: 2,
                        width: { xs: "100%", md: "100%", sm: "auto" },
                        display: "flex",
                        flexDirection: { xs: "column", md: "row", sm: "row" },
                        alignItems: "center",
                      }}
                    >
                      <Grid container>
                        <Grid
                          item
                          sm={5}
                          xs={5}
                          md={5}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            variant="caption"
                            sx={{ fontWeight: "bold" }}
                          >
                            Area
                          </Typography>
                          <Typography variant="caption">
                            {uwbData.location}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sm={5}
                          xs={5}
                          md={5}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            variant="caption"
                            sx={{ alignSelf: "flex-start", fontWeight: "bold" }}
                          >
                            Time Range
                          </Typography>
                          <Typography variant="body2">
                            {`${moment
                              .utc(uwbData.entryTime)
                              .local()
                              .format("hh:mm A")} - ${moment
                              .utc(uwbData.exitTime)
                              .local()
                              .format("hh:mm A")}`}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sm={2}
                          xs={2}
                          md={2}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            variant="caption"
                            sx={{ alignSelf: "flex-start", fontWeight: "bold" }}
                          >
                            Duration
                          </Typography>
                          <Typography variant="caption">
                            {uwbData.duration}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </TimelineContent>
                </TimelineItem>
              ))
            ) : (
              <Typography variant="body1">No data found</Typography>
            )}
          </Timeline>
        </Grid>
        {isLoading && <BackDropLoading open={isLoading} />}
      </Grid>
    );
};
export default UwbTrackingPage;





