import React, { useState } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  MenuItem,
  Divider,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import BackDropLoading from "../../Components/Loading";
import { showToast } from "../../Components/ToastBox";
import DataServices from "../../Services/DataServices";
import { useLocation, useNavigate } from "react-router-dom";

const EditProject = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const dataProduct = [
    { id: 1, name: "PRODUCT A" },
    { id: 2, name: "PRODUCT B" },
  ];

  const validationSchema = Yup.object({
    projectId: Yup.string().required("Project ID is required"),
    projectName: Yup.string().required("Project Name is required"),
    address: Yup.string().required("Address is required"),
    country: Yup.string().required("Country is required"),
    zipCode: Yup.string().required("Zip Code is required"),
    contactPerson: Yup.string().required("Contact Person is required"),
    emailId: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    officePhone: Yup.string().required("Office Phone is required"),
    mobilePhone: Yup.string()
      .matches(/^[0-9]{10}$/, "Must be a 10-digit number")
      .required("Mobile Phone is required"),
    //product: Yup.string().required("Mobile Phone is required"),
  });

  const handleSaveChanges = async (values) => {
    console.log("values", values);
    await editProjectsCall(values);
  };

  const editProjectsCall = async (data) => {
    try {
      setIsLoading(true);
      const response = await DataServices.editProjectsApi(data);
      if (response?.statusCode == 200) {
        try {
          showToast("Project Details Updated successfully", "success");
          navigate("/projects");
        } catch (error) {
          showToast("Something went wrong ", "error");
        }
      } else {
        showToast(response.statusDescription, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Formik
      initialValues={{
        projectId: location?.state?.projectId ?? "",
        projectName: location?.state.projectName ?? "",
        address: location?.state?.address ?? "",
        country: location?.state?.country ?? "",
        zipCode: location?.state?.zipCode ?? "",
        contactPerson: location?.state?.contactPerson ?? "",
        emailId: location?.state?.emailId ?? "",
        officePhone: location?.state?.officePhone ?? "",
        mobilePhone: location?.state?.mobilePhone ?? "",
        product: location?.state?.product ?? "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSaveChanges}
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        values,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h5">Create Project</Typography>
            </Grid>

            {/* Project Info */}
            <Grid item xs={12} md={3} sm={4}>
              <Typography variant="body1">Project Information</Typography>
              <Typography variant="body2">
                Fill in the project details below
              </Typography>
            </Grid>

            <Grid item xs={12} md={9} sm={8}>
              <Card>
                <CardContent>
                  <TextField
                    name="projectId"
                    label="Project ID"
                    value={values.projectId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    margin="normal"
                    size="small"
                    error={touched.projectId && Boolean(errors.projectId)}
                    helperText={touched.projectId && errors.projectId}
                  />

                  <TextField
                    name="projectName"
                    label="Project Name"
                    value={values.projectName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    margin="normal"
                    size="small"
                    error={touched.projectName && Boolean(errors.projectName)}
                    helperText={touched.projectName && errors.projectName}
                  />

                  {/* <TextField
              select
              label={AppText.fields.projectConfigurator}
              value={configurator}
              onChange={(e) => setConfigurator(e.target.value)}
              fullWidth
              margin="normal"
              size="small"
            >
              {dataConfigurator.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField> */}

                  <TextField
                    name="address"
                    label="Address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    margin="normal"
                    size="small"
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                  />

                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6} sm={6}>
                      <TextField
                        name="country"
                        label="Country"
                        value={values.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        margin="normal"
                        size="small"
                        error={touched.country && Boolean(errors.country)}
                        helperText={touched.country && errors.country}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} sm={6}>
                      <TextField
                        name="zipCode"
                        label="Zip Code"
                        value={values.zipCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        margin="normal"
                        size="small"
                        error={touched.zipCode && Boolean(errors.zipCode)}
                        helperText={touched.zipCode && errors.zipCode}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* Contact Info */}
            <Grid item xs={12} md={3} sm={4}>
              <Typography variant="body1">Contact Information</Typography>
              <Typography variant="body2">Provide contact details</Typography>
            </Grid>

            <Grid item xs={12} md={9} sm={8}>
              <Card>
                <CardContent>
                  <TextField
                    name="contactPerson"
                    label="Contact Person"
                    value={values.contactPerson}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    margin="normal"
                    size="small"
                    error={
                      touched.contactPerson && Boolean(errors.contactPerson)
                    }
                    helperText={touched.contactPerson && errors.contactPerson}
                  />

                  {/* <TextField
              select
              label={AppText.fields.jobTitle}
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              fullWidth
              margin="normal"
              size="small"
            >
              {dataJobTittle.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField> */}

                  <TextField
                    name="emailId"
                    label="Email"
                    value={values.emailId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    margin="normal"
                    size="small"
                    error={touched.emailId && Boolean(errors.emailId)}
                    helperText={touched.emailId && errors.emailId}
                  />

                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6} sm={6}>
                      <TextField
                        name="officePhone"
                        label="Office Phone"
                        value={values.officePhone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        margin="normal"
                        size="small"
                        error={
                          touched.officePhone && Boolean(errors.officePhone)
                        }
                        helperText={touched.officePhone && errors.officePhone}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} sm={6}>
                      <TextField
                        name="mobilePhone"
                        label="Mobile Phone"
                        value={values.mobilePhone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        margin="normal"
                        size="small"
                        error={
                          touched.mobilePhone && Boolean(errors.mobilePhone)
                        }
                        helperText={touched.mobilePhone && errors.mobilePhone}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* Project Configuration Info */}
            <Grid item xs={12} md={3} sm={4}>
              <Typography variant="body1">Project Configuration</Typography>
              <Typography variant="body2">
                Choose the project configuration
              </Typography>
            </Grid>

            <Grid item xs={12} md={9} sm={8}>
              <Card>
                <CardContent>
                  <TextField
                    name="product"
                    select
                    label="Product"
                    value={values.product}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    margin="normal"
                    size="small"
                    error={touched.product && Boolean(errors.product)}
                    helperText={touched.product && errors.product}
                  >
                    {dataProduct.map((option) => (
                      <MenuItem key={option.id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <Divider />
                  <Grid style={{ marginTop: "20px", textAlign: "right" }}>
                    <Button variant="outlined" type="reset">
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      style={{ marginLeft: "10px" }}
                    >
                      Save Changes
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {isLoading && <BackDropLoading open={isLoading} />}
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default EditProject;
