import React, { useState } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import { Formik, Field } from "formik";
import * as Yup from "yup";

// Define validation schema with Yup
const validationSchema = Yup.object({
  organizationName: Yup.string().required("Organization name is required"),
  organizationCode: Yup.string().required("Organization code is required"),
  emailId: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  phoneNo: Yup.string()
    .matches(/^[0-9]+$/, "Phone number must contain only digits")
    .required("Phone number is required"),
  address: Yup.string().required("Address is required"),
  country: Yup.string().required("Country name is required"),
  zipCode: Yup.string()
    .matches(/^[0-9]+$/, "Zip code must contain only digits")
    .required("Zip code is required"),
});

const AddNewOrganization = ({ onSave, onCancel }) => {
  return (
    <Dialog open={true} onClose={onCancel}>
      <DialogTitle>Add New Organization</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            organizationName: "",
            organizationCode: "",
            emailId: "",
            phoneNo: "",
            address: "",
            country: "",
            zipCode: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSave(values);
          }}
        >
          {({ errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="h6">
                    Enter your Organization details
                  </Typography>
                </Grid>

                <Grid item xs={9}>
                  <TextField
                    sx={{ width: "93%" }}
                    size="small"
                    label="Organization name"
                    name="organizationName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="normal"
                    error={
                      touched.organizationName && !!errors.organizationName
                    }
                    helperText={
                      touched.organizationName && errors.organizationName
                    }
                  />

                  <TextField
                    sx={{ width: "93%" }}
                    size="small"
                    label="Organization Code"
                    name="organizationCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="normal"
                    error={
                      touched.organizationCode && !!errors.organizationCode
                    }
                    helperText={
                      touched.organizationCode && errors.organizationCode
                    }
                  />

                  <TextField
                    sx={{ width: "93%" }}
                    size="small"
                    label="Email"
                    name="emailId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="normal"
                    error={touched.emailId && !!errors.emailId}
                    helperText={touched.emailId && errors.emailId}
                  />
                  <TextField
                    sx={{ width: "93%" }}
                    size="small"
                    label="Phone no"
                    name="phoneNo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="normal"
                    error={touched.phoneNo && !!errors.phoneNo}
                    helperText={touched.phoneNo && errors.phoneNo}
                  />
                  <TextField
                    sx={{ width: "93%" }}
                    size="small"
                    label="Address"
                    name="address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="normal"
                    error={touched.address && !!errors.address}
                    helperText={touched.address && errors.address}
                  />

                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ width: "85%" }}
                        size="small"
                        label="Country name"
                        name="country"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        error={touched.country && !!errors.country}
                        helperText={touched.country && errors.country}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        sx={{ width: "85%" }}
                        size="small"
                        label="Zip Code"
                        name="zipCode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        error={touched.zipCode && !!errors.zipCode}
                        helperText={touched.zipCode && errors.zipCode}
                      />
                    </Grid>
                  </Grid>

                  <Divider />
                  <div style={{ marginTop: "20px", textAlign: "right" }}>
                    <Button variant="outlined" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      style={{ marginLeft: "10px" }}
                    >
                      Save
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewOrganization;
