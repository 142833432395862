import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";

import {
  Grid,
  Autocomplete,
  Typography,
  TextField,
  Toolbar,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DataServices from "../Services/DataServices";
import { showToast } from "../Components/ToastBox";
import BackDropLoading from "../Components/Loading";
import { useNavigate } from "react-router-dom";
import DeleteConfirmationDialog from "../Components/DeleteConfirmationDialog";
import { Chip } from "@mui/material";

const UserList = () => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");
  const [orgDropDownOptions, setOrgDropDownOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  useEffect(() => {
    fetchOrgDropdownData();
  }, []);

  const handleModal = (data) => {
    setModalOpen(true);
    setUserToDelete(data);
  };

  const fetchOrgDropdownData = async () => {
    try {
      setIsLoading(true);
      const response = await DataServices.getOrgDropDownDetailsApi();
      if (response.statusCode == 200) {
        try {
          var dummy = [...response.response.body];
          if (orgDropDownOptions < 1) {
            setOrgDropDownOptions(dummy);
          }
        } catch (error) {
          showToast("Something went wrong ", "error");
        }
      } else {
        showToast(response.statusDescription, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUserDetailsCall(selectedOrganization ?? "");
  }, [selectedOrganization]);

  const getAllUserDetailsCall = async (data) => {
    try {
      setIsLoading(true);
      const response = await DataServices.getUsersDetilsApi(data);
      if (response?.statusCode == 200) {
        try {
          setUserList(response.response.body);
        } catch (error) {
          showToast("Something went wrong ", "error");
        }
      } else {
        showToast(response?.statusDescription, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteConfirm = async () => {
    setModalOpen(false);
    if (userToDelete.length > 0) {
      try {
        setIsLoading(true);
        const response = await DataServices.deleteUserApi(userToDelete);
        if (response?.statusCode == 200) {
          try {
            await getAllUserDetailsCall();
            showToast(response.response.body.message, "success");
          } catch (error) {
            showToast("Something went wrong ", "error");
          }
        } else {
          showToast(response?.statusDescription, "error");
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      showToast("Unable to process", "error");
    }
  };
  const editIcon = <EditIcon color="primary" />;
  const deleteIcon = <DeleteIcon color="action" />;

  const columns = [
    {
      name: "userCode",
      label: "User ID",
      options: {
        sort: true,

        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "firstName",
      label: "User Name",
      options: {
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "email",
      label: "Email Address",
      options: {
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "mobileNo",
      label: "Mobile No",
      options: {
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "status",
      label: "Subscription Status",
      options: {
        sort: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          let color, backgroundColor;

          switch (value?.toLowerCase()) {
            case "active":
              color = "#fff";
              backgroundColor = "#545F71";
              break;
            case "inactive":
              color = "#000";
              backgroundColor = "lightgrey";
              break;
            case "expiring":
              color = "#fff";
              backgroundColor = "#D4AF37";
              break;
            case "pending":
              color = "orange";
              backgroundColor = "lightyellow";
              break;
            default:
              color = "#ffffff";
              backgroundColor = "#ffffff";
              break;
          }
          return (
            <div style={{ textAlign: "center" }}>
              {value ? (
                <Chip
                  label={value}
                  style={{
                    backgroundColor,
                    color,
                  }}
                />
              ) : null}
            </div>
          );
        },
      },
    },
    {
      name: "Actions",
      options: {
        sort: false,
        customBodyRenderLite: (dataIndex) => (
          <div>
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => handleEdit(userList[dataIndex])}
            >
              {editIcon}
            </span>
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => handleModal(userList[dataIndex]?.email)}
            >
              {deleteIcon}
            </span>
          </div>
        ),
      },
    },
  ];
  const options = {
    download: false,
    print: false,
    onRowClick: false,
    filter: false,
    viewColumns: false,
    selectableRows: "none",
    responsive: "standard",
  };

  const handleEdit = (rowData) => {
    // Handle edit action, rowData contains the data for the clicked row
    navigate("/users/EditUser", { state: rowData });
  };

  // const organization = [
  //   { label: "AITS" },
  //   { label: "Organization B" },
  //   { label: "Organization C" },
  //   { label: "Organization D" },
  // ];

  return (
    <Grid>
      <Grid>
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">User List</Typography>
        </Toolbar>
      </Grid>
      <Grid container spacing={0} direction="column">
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="OrganizationName"
            options={orgDropDownOptions}
            sx={{ width: 250, float: "left", marginBottom: "20px" }}
            onChange={(event, newValue) =>
              setSelectedOrganization(newValue?.value)
            }
            renderInput={(params) => (
              <TextField {...params} label="Organization Name" />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <MUIDataTable
            title={<div></div>}
            data={userList}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>

      <DeleteConfirmationDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleDeleteConfirm}
      />
      {isLoading && <BackDropLoading open={isLoading} />}
    </Grid>
  );
};

export default UserList;
